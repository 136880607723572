import React, {useEffect, useRef, useState} from 'react';
import {useLocaleKeys} from '../../../../../locale-keys/LocaleKeys';
import {DynamicStep, DynamicStepComponentProps} from '../../StepsManager/Components/DynamicStep';
import {StepHeader} from '../../StepsManager/Components/StepHeader';
import {useControllerProps} from '../../../Widget/ControllerContext';
import {StepImplementationProps, StepState, SummaryLocation} from '../../../../../types/app.types';
import {PlaceOrder} from './Components/PlaceOrder/PlaceOrder';
import {usePaymentsApi} from '../../../WithPaymentsApi/WithPaymentsApi';
import {useBillingData, withBillingData} from '../PaymentStep/WithBillingData/WithBillingData';
import {useMemberDetailsData, withMemberDetailsData} from '../../../MemberDetails/WithMemberDetailsData';
import {PaymentWidgetWrapper} from '../PaymentStep/PaymentWidgetWrapper';
import {BillingDetails} from '../PaymentStep/BillingDetails/BillingDetails';
import {CheckoutModel} from '../../../../../domain/models/Checkout.model';
import {StatesButtonStates, Divider} from 'wix-ui-tpa/cssVars';
import {UsePlaceOrderButtonState} from './UsePlaceOrderButtonState';
import {DetailsFormButtons} from '../../StepsManager/Components/DetailsFormButtons/DetailsFormButtons';
import {ADD_NEW_ADDRESS_ID, SPECS} from '../../../constants';
import {useFunctionResultObservation} from '@wix/function-result-observation';
import {Summary} from '../../../Summary/Summary';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {classes} from './PaymentAndPlaceOrderStep.st.css';

export enum PaymentAndPlaceOrderDataHook {
  root = 'PaymentAndPlaceOrderDataHook.root',
  header = 'PaymentAndPlaceOrderDataHook.header',
  open = 'PaymentAndPlaceOrderDataHook.open',
  summary = 'PaymentAndPlaceOrderDataHook.summary',
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const InternalPaymentAndPlaceOrderStep = ({index}: DynamicStepComponentProps) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const localeKeys = useLocaleKeys();
  const {paymentsApi} = usePaymentsApi();
  const {experiments} = useExperiments();

  const {
    checkoutStore,
    formsStore: {areFormsLoaded},
    stepsManagerStore: {stepsList},
    memberStore: {isMember},
    paymentStore: {shouldRequirePayment},
    isOneColumnView,
  } = useControllerProps();

  const shouldSupportNewMobileSummaryBehavior = experiments.enabled(SPECS.SupportNewMobileSummaryBehavior)
    ? isOneColumnView
    : true;
  const {isPlaceOrderButtonDisabled} = checkoutStore;
  const {initForm, contactCountry, getBillingFormDataForSubmit, isFormValid, billingSameAsShipping} = useBillingData();
  const {withObservation} = useFunctionResultObservation();
  const setBillingDetails = withObservation(checkoutStore, 'setBillingDetails');

  const {resetMemberDetailsState, editMode, isSetAsDefaultChecked, setEditMode, selectedAddressesServiceId} =
    useMemberDetailsData();

  const {setPlaceOrderButtonState, setShouldPlaceOrderButtonBeDisabled} = UsePlaceOrderButtonState();
  const shouldShowFormDetailsButtons =
    isMember &&
    (editMode || selectedAddressesServiceId === ADD_NEW_ADDRESS_ID || !selectedAddressesServiceId) &&
    !billingSameAsShipping;
  const [saveBillingAddressButtonState, setSaveBillingAddressButtonState] = useState(StatesButtonStates.IDLE);
  const [isSaveBillingAddressButtonBeDisabled, setIsSaveBillingAddressButtonDisabled] = useState(false);

  const stepState = stepsList[index].state;

  const scrollToHeader = () => {
    headerRef?.current?.scrollIntoView();
  };

  useEffect(
    () => {
      if (stepState === StepState.OPEN && shouldRequirePayment) {
        void paymentsApi?.expand();
        setPlaceOrderButtonState(StatesButtonStates.IDLE);
        setShouldPlaceOrderButtonBeDisabled(!areFormsLoaded || isPlaceOrderButtonDisabled);
        initForm();
        if (isMember) {
          resetMemberDetailsState();
        }
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */ [stepState]
  );

  const validateAndSubmitMemberBillingDetails = async () => {
    setSaveBillingAddressButtonState(StatesButtonStates.IN_PROGRESS);
    setIsSaveBillingAddressButtonDisabled(true);
    const isBillingFormValid = await isFormValid();
    /* istanbul ignore next */
    if (isBillingFormValid) {
      await setBillingDetails({
        ...getBillingFormDataForSubmit(),
        addressesServiceId: selectedAddressesServiceId ?? ADD_NEW_ADDRESS_ID,
        setAsDefault: isSetAsDefaultChecked,
      });

      setEditMode(false);
      setShouldPlaceOrderButtonBeDisabled(!areFormsLoaded || isPlaceOrderButtonDisabled);
    }

    setSaveBillingAddressButtonState(StatesButtonStates.IDLE);
    setIsSaveBillingAddressButtonDisabled(false);
  };

  return (
    <>
      <StepHeader
        index={index}
        dataHook={PaymentAndPlaceOrderDataHook.header}
        label={shouldRequirePayment ? localeKeys.checkout.payment() : localeKeys.checkout.place_order.$value()}
        ref={headerRef}
      />
      {shouldRequirePayment && <PaymentWidgetWrapper stepState={stepState} country={contactCountry} />}
      {stepState === StepState.OPEN && (
        <div data-hook={PaymentAndPlaceOrderDataHook.open}>
          {shouldRequirePayment && (
            <>
              <BillingDetails />
              {shouldShowFormDetailsButtons && (
                <DetailsFormButtons
                  onCancel={scrollToHeader}
                  onContinue={() => void validateAndSubmitMemberBillingDetails()}
                  continueButtonDisabled={isSaveBillingAddressButtonBeDisabled}
                  continueButtonState={saveBillingAddressButtonState}
                  label={localeKeys.checkout.billingAddress.save.cta()}
                />
              )}
              <Divider className={classes.stepDivider} direction="horizontal" />
            </>
          )}
          <PlaceOrder />
          {shouldSupportNewMobileSummaryBehavior && (
            <Summary
              dataHook={PaymentAndPlaceOrderDataHook.summary}
              location={SummaryLocation.paymentAndPlaceOrderStep}
            />
          )}
        </div>
      )}
    </>
  );
};

const WrappedInternalPaymentAndPlaceOrderStep = withMemberDetailsData(
  withBillingData(InternalPaymentAndPlaceOrderStep),
  (checkout: CheckoutModel) => checkout.billingInfo,
  'validateBillingAddress'
);

export const PaymentAndPlaceOrderStep = ({index}: StepImplementationProps) => {
  return (
    <DynamicStep
      index={index!}
      dataHook={PaymentAndPlaceOrderDataHook.root}
      component={WrappedInternalPaymentAndPlaceOrderStep}
    />
  );
};
