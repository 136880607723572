import React from 'react';
import {Text} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../../Widget/ControllerContext';
import {getTotal} from '../../../../domain/utils/total.util';
import {classes} from './FoldableSummarySection.st.css';

export enum FoldableSummarySectionDataHook {
  root = 'FoldableSummarySectionDataHooks.root',
  content = 'FoldableSummarySectionDataHook.content',
  total = 'FoldableSummarySectionDataHook.total',
}

export interface FoldableSummarySectionProps {
  onClick: () => void;
  isOpen: boolean;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const FoldableSummarySection = ({onClick, isOpen}: FoldableSummarySectionProps) => {
  const localeKeys = useLocaleKeys();
  const {
    checkoutStore: {checkout},
  } = useControllerProps();

  return (
    <div
      data-hook={FoldableSummarySectionDataHook.root}
      className={classes.root}
      onClick={onClick}
      role={'button'}
      tabIndex={0}
      onKeyDown={onClick}>
      <Text data-hook={FoldableSummarySectionDataHook.content}>
        {isOpen
          ? localeKeys.checkout.mobile.hideOrderSummary.label({number: checkout.numberOfItems})
          : localeKeys.checkout.mobile.showOrderSummary.label({number: checkout.numberOfItems})}
      </Text>
      <Text data-hook={FoldableSummarySectionDataHook.total}>{getTotal(checkout)}</Text>
    </div>
  );
};
