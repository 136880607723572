import React, {useState} from 'react';
import {classes} from './Summary.st.css';
import {useControllerProps} from '../Widget/ControllerContext';
import {TotalsSection} from './TotalsSection/TotalsSection';
import {CouponInput} from './CouponInput/CouponInput';
import {GiftCardInput} from './GiftCardInput/GiftCardInput';
import {FoldableSummarySection} from './FoldableSummarySection/FoldableSummarySection';
import {SummaryTitle} from './SummaryTitle/SummaryTitle';
import {LineItemSection} from './LineItemSection/LineItemSection';

export const TopMobileSummary = () => {
  const {
    checkoutStore: {shouldShowGiftCardSection, shouldShowCouponSection},
  } = useControllerProps();

  const [isFoldableSummarySectionOpen, setIsFoldableSummarySectionOpen] = useState(false);
  const toggleFoldableSummarySection = () => {
    setIsFoldableSummarySectionOpen(!isFoldableSummarySectionOpen);
  };

  return (
    <>
      <FoldableSummarySection onClick={toggleFoldableSummarySection} isOpen={isFoldableSummarySectionOpen} />
      {isFoldableSummarySectionOpen && (
        <div className={classes.summary}>
          <SummaryTitle />
          <LineItemSection />
          <div className={classes.couponAndGiftCard}>
            {shouldShowCouponSection && <CouponInput />}
            {shouldShowGiftCardSection && <GiftCardInput />}
          </div>
          <TotalsSection />
        </div>
      )}
    </>
  );
};
